<template>
  <div class="container">
    <!-- 文件名称搜索框 -->
    <div class="header-item">
      <el-input placeholder="请输入海报标题" suffix-icon="el-icon-search" clearable
        v-model="searchText"  
        @clear="getList"  
        @change="searchList">
      </el-input>
    </div>
    <!-- 海报类型 -->
    <div class="header-item">
      <span>分类：</span>
      <el-select v-model="selectValue"  placeholder="全部" @change="getList" >
        <el-option
          v-for="item in optionList"
          :key="item.id"
          :label="item.name"
          :value="item.id">
        </el-option>
    </el-select>
    </div> 
    <!-- 海报状态 -->
    <div class="header-item">
      <span>状态：</span>
      <el-select v-model="classifyStatusValue"  placeholder="全部" @change="getList">
        <el-option
          v-for="item in classifyStatusOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
    </el-select>
    </div> 

    <!-- 添加企业宣传册按钮 -->
    <div class="header-item" style="float: right">
      <el-button @click="$winRedirectPage(1, '#/poster/edit')" type="primary" icon="el-icon-plus">新增海报</el-button>
    </div>

    <!-- 文件夹列表及操作 -->
    <div class="brochure-item">
      <el-table ref="multipleTable" tooltip-effect="dark" style="width: 100%; margin-top: 10px" 
        header-row-class-name="table-header-style"
        :data="posterLists"
        :header-cell-style="{'background-color': 'rgba(249, 249, 249)'}"
        @selection-change="handleSelectionChange">
        <el-table-column  type="selection" width="55"></el-table-column>
        <el-table-column label="海报" width="80">
          <template slot-scope="scope">
            <div @click="preview(scope.row)" class="item-logo" :style="{
              'background-image': `url(${scope.row.configs.logo || $configs.headImg})`}">
            </div>
          </template>
        </el-table-column>
        <el-table-column label="海报标题" prop="title"></el-table-column>
        <el-table-column label="海报分类" prop="categoryName"></el-table-column>
        <el-table-column label="投稿人" prop="userName"></el-table-column>
        <el-table-column label="部门" prop="teamName"></el-table-column>
        <el-table-column label="是否上架" width="100">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.status"
              @change="updateStatus(scope.row)"
              :active-value="1" :inactive-value="0"
              active-color="#1a9afd" inactive-color="#999999"></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="上架时间" width="200">
          <template slot-scope="scope">
            <p>{{ scope.row.updatedAt | dateFormat('YYYY-MM-DD HH:mm:ss') }}</p>
          </template>
        </el-table-column>  
        <!-- 删除、重命名 -->
        <el-table-column label="操作" width="160">
          <template slot-scope="scope">
            <el-button @click="updateStatus(scope.row, -1)" type="text" style="color: #ff0000">删除</el-button>
            <el-button @click="$winRedirectPage(1, `#/poster/edit?sid=${scope.row.id}`)" type="text">编辑</el-button>
            <el-button @click="updateBrochure(scope.row)" type="text">重命名</el-button>
          </template>
        </el-table-column>
      </el-table>

      <div style="margin-top: 20px; overflow: hidden">
      <!-- 批量操作按钮 -->
        <el-button :disabled="!multipleSelection" @click="updateMultipleStatus('release')">批量发布</el-button>
        <el-button :disabled="!multipleSelection" @click="updateMultipleStatus('dismount')">批量下架</el-button>
        <el-button :disabled="!multipleSelection" @click="updateMultipleStatus('delete')">批量删除</el-button>
        <!-- 分页 -->
        <div style="float: right">
          <el-pagination
            :background="true"
            @current-change="getList"
            :current-page="page.index"
            :page-size="page.number"
            layout="total,prev, pager, next, jumper"
            :total="page.total">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ResourceDialog from '@/websites/cms/js/dialog';
export default {
  props:['goPosterListID'],
  data(){
    return {
      searchText: '', // 宣传册搜索框字段
      page: { // 分页查询信息
        index: 1,
        number: 5,
        total: 0
      },
      posterLists: [], // 宣传册总列表
      posterList: [], // 宣传册展示列表
      optionList: [], //分类类型列表
      selectValue: '',
      classifyStatusOptions: [ //海报状态上架下架显示列表
        {
          value: '',
          label: '全部'
        },
        {
          value: '1',
          label: '已上架'
        }, {
          value: '0',
          label: '已下架'
      }],
      classifyStatusValue: '',
      multipleSelection: "", // 用户当前选中的海报ID组
      cid:'',
    }
  },
  created() {
    this.getList();
    this.posterCategoryList();
  },
  methods: {
    // 分页查询列表
    async getList(pageIndex, id=""){
      this.cid = id;
      let index = 0;
      if(pageIndex) index = parseInt(pageIndex)
      this.page.index = index;
      let params = {
        type:51,
        status: this.classifyStatusValue,
        pageIndex: pageIndex || this.page.index,
        pageNumber: this.page.number,
        categoryId: this.selectValue || this.cid,
      }
      let res = await this.$ZGManager.getMaterialList(params);
      if(res.status == 200 && res.data){
        let tmp = []
        res.data.list.map((e) => {
          if(e.configs){
            e.configs = JSON.parse(e.configs);
            if(e.configs.logo){
              tmp.push(e)
            }
          }         
        });
        this.posterLists = tmp;
        this.page.total = res.data.totalRows;
      }
    },

    //搜索海报列表
    async searchList() {
      let params = {
        type:51,
        title:this.searchText,
        status: this.classifyStatusValue,
        pageIndex: 1,
        pageNumber: this.page.number,
        categoryId: this.selectValue || this.cid,
      }
      let res = await this.$ZGManager.getMaterialList(params);
      if(res.status == 200 && res.data){
        res.data.list.map(e => e.configs = JSON.parse(e.configs));
        this.posterLists = res.data.list;
        this.page.total = res.data.totalRows;
      }
    },

    //获取海报分类数据
    async posterCategoryList() {
      let params = {type:51}
      let  res = await this.$ZGManager.materialCategoryList(params);
      if(res.status != 200) return;
      this.optionList =  res.data;
      this.optionList.unshift({id:'', name:"全部"});
    },

    // 修改状态
    async updateStatus(item, type){
      if(type == -1){
        let dialog = await this.$confirm('此操作将永久删除该海报, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        });
        if(dialog != "confirm") return;
      }
      let res = await this.$ZGManager.updateMaterialStatus(item.id, type || item.status);
      if(res.status != 200) return;
      if(type == -1){
        this.getList();
      }else{
        let index = this.posterList.findIndex(e => e.id == item.id);
        let data = this.posterList[index];
        this.posterList.splice(index, 1, data);
      }

    },

    //批量修改状态
    async updateMultipleStatus(type){
      let status = "";
      switch(type){
        case 'release': 
          status = "1";
          break;
        case 'dismount':
          status = "0";
          break;
        case 'delete':
          status = "-1";
          break;
        default: status = "";
      }
      if(type == "delete") {
        let dialog = await this.$confirm('此操作将永久删除该海报, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        });
      }
      let res = await this.$ZGManager.updataPosterStatus(this.multipleSelection, status);
      if(res.status == 200){
        this.getList();
      }  
    },

    // 更新信息
    async updateBrochure(item){
      let dialog = await this.$prompt('请输入海报名称', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValue: item.title,
        inputPattern: /^.{2,30}$/,
        inputErrorMessage: '海报名称应在2-15个字'
      });
      if(dialog.action != 'confirm') return;
      let configs = item.configs;
      configs.title = dialog.value;
      let res = await this.$ZGManager.updateMaterial(item.id, { title: dialog.value, configs: JSON.stringify(configs) });
      if(res.status != 200) return;
      let index = this.posterList.findIndex(e => e.id == item.id);
      let data = this.posterList[index];
      data.title = res.data.title;
      this.posterList.splice(index, 1, data);
    },

    // 预览海报
    preview(item){
      ResourceDialog.show({
        type: 'previewResource',
        resource: item,
        resourceType: 'image',
        resourceTitle: item.title,
        resourceUrl: item.configs.logo
      })
    },

    // 选中海报后的处理
    handleSelectionChange(val) {
      this.multipleSelection = "";
      val.map(e => this.multipleSelection += `${e.id},`);
      this.multipleSelection = this.multipleSelection.substring(0, this.multipleSelection.length - 1);
    }
  }
}
</script>

<style lang="scss" scoped>
.content{
  padding: 10px;
  background-color: #fff;
  margin-bottom: 10px;
  font-size: 14px;
  .progress{
    display: inline-block;
    width: 200px;
    ::v-deep .el-progress-bar__outer{
      height: 16px!important;
    }
  }
  .title-l1{
    font-size: 14px;
    font-weight: bold;
    color: rgb(78, 78, 78);
    vertical-align: middle;
  }
}
.header-item{
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
  vertical-align: middle;
}
.breadcrumb{
  padding: 15px 10px;
  background-color: rgb(246, 247, 248);
  border-bottom: 1px solid #e8e8e8;
  margin-top: 10px;
  overflow: hidden;
  ::v-deep .el-breadcrumb{
    width: 800px;
    display: inline-block;
  }
  .nav{
    color: #777;
  }
  .link{
    color: #578bcf;
    cursor: pointer;
  }
  .padding-align{
    padding: 0 10px;
  }
  .number{
    float: right;
    margin-right: 30px;
    color: #777;
  }
}
.table-title{
  color: #578bcf;
  cursor: pointer;
}
.brochure-item{
  .item-logo{
    width: 60px;
    height: 60px;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
    cursor: pointer;
    &:hover{
      opacity: 0.7;
    }
  }
}
</style>